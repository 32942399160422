<template>
    <div id="app" class="width-wrapper">
        <DexHeader :appName="'Dex Installation Tool'">
            <DexHeaderNavSection>
                <DexHeaderNavItem v-if="hasClientRoles(['ditinstallation'])" :to="'/DITOverview'">Overview</DexHeaderNavItem>
                <DexHeaderNavItem v-if="hasClientRoles(['ditplanner'])" :to="'/DITPlanning'">Planning</DexHeaderNavItem>
                <DexHeaderNavItem v-if="hasClientRoles(['ditinstallation'])" :to="'/DITInstallation'">Installation</DexHeaderNavItem>
                <DexHeaderNavItem v-if="hasClientRoles(['ditplanner'])" :to="'/DITSensorOnboarding'">Sensor Onboarding</DexHeaderNavItem>
                <DexHeaderNavItem v-if="hasClientRoles(['ditplanner'])" :to="'/DITAssetMapping'">Asset Mapping</DexHeaderNavItem>
                <DexHeaderNavItem v-if="hasClientRoles(['ditplanner'])" :to="'/DITLifecycle'">Lifecylce</DexHeaderNavItem>                
                <DexHeaderNavItem v-if="hasClientRoles(['ditdev'])" :to="'/DITMap'">Map</DexHeaderNavItem>                
            </DexHeaderNavSection>
            <DexHeaderNavSection>
                <DexHeaderUserItemExpandable :itemLabel="getLang.toUpperCase()">
                    <DexHeaderDropdownGroup :itemLabel="getText('set-lang')">
                        <DexHeaderDropdownGroupItem :src="'flags/de.svg'" @click.native="setLanguage({ lang: 'de' })">
                            {{ getText("lang-de") }}
                        </DexHeaderDropdownGroupItem>
                        <DexHeaderDropdownGroupItem :src="'flags/uk.svg'" @click.native="setLanguage({ lang: 'en' })">
                            {{ getText("lang-en") }}
                        </DexHeaderDropdownGroupItem>
                    </DexHeaderDropdownGroup>
                </DexHeaderUserItemExpandable>
                <DexHeaderUserItem @click.native="tryLogin" v-if="!getProfile">
                    <a>{{ getText("login") }}</a>
                </DexHeaderUserItem>
                <DexHeaderUserItemExpandable :initials="getInitials" v-if="getProfile">
                    <DexHeaderDropdownGroup :itemLabel="`${getText('welcome-back')} ${getProfile.firstName}`">
                        <DexHeaderDropdownGroupItem @click.native="tryLogout">
                            {{ getText("logout") }}
                        </DexHeaderDropdownGroupItem>
                    </DexHeaderDropdownGroup>
                </DexHeaderUserItemExpandable>
                <DexHeaderUserItem>
                    <img src="./assets/images/dfm-logo.png" />
                </DexHeaderUserItem>
            </DexHeaderNavSection>
        </DexHeader>
        <router-view />
        <DexFooter>
            <li>
                <router-link to="/impressum">{{ getText("imprint") }}</router-link>
            </li>
            <li>
                <a href="https://www.de.issworld.com/de-de/datenschutz"
                   target="_blank">{{ getText("privacy") }}</a>
            </li>
            <li>
                <a href="https://www.de.issworld.com/de-de/karriere/ihre-karriere-bei-iss/karriere"
                   target="_blank">{{ getText("careers") }}</a>
            </li>
            <li>
                <a href="https://www.de.issworld.com/de-de/ueber-iss/people-make-places/ueber-iss"
                   target="_blank">{{ getText("about-iss") }}</a>
            </li>
            <li>
                <a href="https://www.de.issworld.com/de-de/ueber-iss/kontakt/kontakt-zu-iss"
                   target="_blank">{{ getText("contact") }}</a>
            </li>
        </DexFooter>
        <PopupWindow v-if="loader" :canBeToggled="false" :coverWindow="true" :clearBackground="true" :loader="true"></PopupWindow>
    </div>
</template>

<script>
    import DexHeader from "@/components/default/header/DexHeader.vue";
    import DexHeaderNavSection from "@/components/default/header/DexHeaderNavSection.vue";
    import DexHeaderNavItem from "@/components/default/header/DexHeaderNavItem.vue";
    import DexHeaderUserItem from "@/components/default/header/DexHeaderUserItem.vue";
    import DexHeaderUserItemExpandable from "@/components/default/header/DexHeaderUserItemExpandable.vue";
    import DexHeaderDropdownGroup from "@/components/default/header/DexHeaderDropdownGroup.vue";
    import DexHeaderDropdownGroupItem from "@/components/default/header/DexHeaderDropdownGroupItem.vue";
    import DexFooter from "@/components/default/DexFooter.vue";
    import PopupWindow from "@/components/default/PopupWindow.vue";
    import { mapGetters, mapActions, mapMutations } from "vuex";
    import * as dexlib from "@/assets/js/dexlib.js";

    export default {
        name: "app",
        components: {
            DexHeader,
            DexHeaderNavSection,
            DexHeaderNavItem,
            DexHeaderUserItem,
            DexHeaderUserItemExpandable,
            DexHeaderDropdownGroup,
            DexHeaderDropdownGroupItem,
            DexFooter,
            PopupWindow
        },
        computed: {
            ...mapGetters("lang", {
                getText: "getText",
                getLang: "getActiveLang",
            }),
            ...mapGetters("keycloak", {
                getKeycloak: "getKeycloak",
                getProfile: "getProfile",
                getInitials: "getInitials",
                hasClientRoles: "hasClientRoles",
                getCheckSso: "getSsoCheck"
            }),
            ...mapGetters("loader", {
                loader: "getLoader"
            })
        },
        methods: {
            ...mapMutations("lang", {
                setLanguage: "setActiveLang",
            }),
            ...mapActions("keycloak", {
                tryLogin: "tryLogin",
                tryLogout: "tryLogout",
                initKeycloak: "initKeycloak"
            }),
            contentMinHeightHandler: function () {
                if (!document.querySelector("#app>div")) return;
                document.querySelector("#app>div").style.setProperty(
                    "min-height",
                    `calc(100vh - ${window.getComputedStyle(document.querySelector("header")).height
                    } - ${window.getComputedStyle(document.querySelector("footer")).height
                    })`
                );
            },
            downloadXml: function () {
                this.replaceOverlayWindowItem({ type: "download" });
            },
            uploadXml: function () {
                this.replaceOverlayWindowItem({ type: "upload" });
            },
            resetXml: function () {
                this.replaceOverlayWindowItem({ type: "reset" });
            }
        },
        async mounted() {
            await this.initKeycloak();
            if (dexlib.getParam("lang")) {
                this.setLanguage({ lang: dexlib.getParam("lang") });
            } else {
                this.setLanguage({ lang: navigator.language.slice(0, 2) });
            }
            this.contentMinHeightHandler();
            window.addEventListener("resize", () => {
                this.contentMinHeightHandler();
            });
        },
        updated() {
            this.contentMinHeightHandler();
        }
    };
</script>