import { render, staticRenderFns } from "./DexFooter.vue?vue&type=template&id=48e0289f&scoped=true"
var script = {}
import style0 from "./DexFooter.vue?vue&type=style&index=0&id=48e0289f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48e0289f",
  null
  
)

export default component.exports