import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/default/Home.vue'
import Impressum from '../views/default/Impressum.vue'
import PageNotFound from '../views/default/PageNotFound.vue'

// Project Specific Pages
import DITOverview from '../views/DITOverview.vue'
import DITInstallation from '../views/DITInstallation.vue'
import DITSensorOnboarding from '../views/DITSensorOnboarding'
import DITPlanning from '../views/DITPlanning.vue'
import DITLifecycle from '../views/DITLifecycle.vue'
import DITAssetMapping from '../views/DITAssetMapping.vue'
import DITMap from '../views/DITMap.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/impressum',
        name: 'Impressum',
        component: Impressum
    },
    {
        path: '*',
        name: 'Page not found',
        component: PageNotFound
    },
    {
        path: '/ditoverview',
        name: 'DITOverview',
        component: DITOverview
    },
    {
        path: '/ditplanning',
        name: 'DITPlanning',
        component: DITPlanning
    },
    {
        path: '/ditinstallation',
        name: 'DITInstallation',
        component: DITInstallation
    },
    {
        path: '/ditsensoronboarding',
        name: 'DITSensorOnboarding',
        component: DITSensorOnboarding
    },
    {
        path: '/DITLifecycle',
        name: 'DITLifecycle',
        component: DITLifecycle
    },
    {
        path: '/DITAssetMapping',
        name: 'DITAssetMapping',
        component: DITAssetMapping
    }
    ,
    {
        path: '/DITMap',
        name: 'DITMap',
        component: DITMap
    }
]

const router = new VueRouter({
    routes,
    mode: "history"
})

router.afterEach(() => {
    document.querySelector("html").scrollTo(0, 0);
});

export default router