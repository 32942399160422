<template>
    <div class="pad-child" align="center">
        <div v-if="hasClientRoles(['ditplanner'])">
            <h2>Lifecycle</h2>
            <PlanningSelector :level="1" ref="childRef" @buildingChangedEvent="click" />
            <div>
                <br />
                <button @click="click" class="box">Load</button>
            </div>
            <br />
            <div>
                <label>Device search </label><input v-model=devicesearch /> <br />
            </div>
            <br />
            <div class="table-wrapper">
                <table style="height: 50vh;">
                    <thead>
                        <tr>
                            <th @click="sort('id')">DeviceID</th>
                            <th @click="sort('deviceUid')">DeviceUid</th>
                            <th @click="sort('displayName')">DisplayName</th>
                            <th @click="sort('deviceType')">DeviceType</th>
                            <th @click="sort('lifecycle')">Lifecycle</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="device in filtereddevicelist" :key="device.id">
                            <td><center>{{device.id}}</center></td>
                            <td><center>{{device.deviceUid}}</center></td>
                            <td><center>{{device.displayName}}</center></td>
                            <td><center>{{device.deviceType}}</center></td>
                            <td>
                                <center>
                                    <select v-on:change="onFloorChnage(device,$event.target.value)">
                                        <option v-for="(lifecylce) in lifecylces" :key="lifecylce.id" :value=lifecylce.id :selected="lifecylce.id==device.lifecycle">{{lifecylce.name}}</option>
                                    </select>
                                </center>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
        <div v-else>
            <h2>401 Unauthorized</h2>
        </div>
    </div>
</template>

<script>
    import axios from "axios"
    import { mapGetters, mapActions } from "vuex"
    import PlanningSelector from '../components/dit/PlanningSelector.vue'
    export default {
        name: "DITLifecycle",
        data() {
            return {
                deviceList: [],
                devicesearch: '',
                lifecylces: []
            }
        },
        components: {
            PlanningSelector
        },
        computed: {
            ...mapGetters("lang", {
                getText: "getText"
            }),
            ...mapGetters("keycloak", {
                getProfile: "getProfile",
                hasClientRoles: "hasClientRoles"
            }),
            filtereddevicelist:
            {
                get() {
                    if (this.deviceList != null) {
                        if (this.devicesearch !== null && this.devicesearch !== '') {
                            return this.deviceList.filter(obj => {
                                return obj.deviceUid.toLowerCase().includes(this.devicesearch.toLowerCase()) ; 
                            });
                        }
                        else {
                            return this.deviceList;
                        }
                    }
                    else {
                        return []
                    }
                }
            }
        },
        methods: {
            ...mapActions("loader", {
                replaceLoader: "replaceLoader"
            }),
            searchdevice() {
                this.deviceList = null;
                this.replaceLoader({ loader: true });
                return axios.get("/api/dit/FindDevices",
                    {
                        params: { SearchString: this.devicesearchstring },
                        headers: {
                            'ConnectionName': this.$store.state.connection.connectionstring,
                            'mappedStatus': 'All'
                        }
                    }).then(res => {
                        if (!res.data.status) {
                            throw res.data.message[0];
                        }
                        this.deviceList = res.data.data;
                    }).catch(e => {
                        alert(e.message);
                    }).finally(() => {
                        this.replaceLoader({ loader: false });
                    });
            },
            getLifecycles() {
                this.lifecylces = null;
                this.replaceLoader({ loader: true });
                return axios.get("/api/dit/GetLifecylces").then(res => {
                    if (!res.data.status) {
                        throw res.data.message[0];
                    }
                    this.lifecylces = res.data.data;
                }).catch(e => {
                    alert(e.message);
                }).finally(() => {
                    this.replaceLoader({ loader: false });
                });
            },
            onFloorChnage(device, newlifecylce) {
                if (!this.getProfile) return;
                this.changeLifecylce(device.id, newlifecylce)
            },
            changeLifecylce(deviceid, lifecycleid) {
                this.replaceLoader({ loader: true });
                var msg = {
                    'DeviceID': deviceid,
                    'Lifecycle': lifecycleid
                };
                return axios.post("/api/dit/ChangeDeivceLifecyle", msg,
                    {
                        headers: {
                            'ConnectionName': this.$store.state.connection.connectionstring
                        }
                    }).then(res => {
                        if (!res.data.status) {
                            throw res.data.message[0];
                        }
                        switch (res.data.data) {
                            case "-2":
                                break;
                            case "-1":
                                break;
                            case "1":
                            default:
                                break;
                        }
                    }).catch(e => {
                        alert(e.message);
                    }).finally(() => {
                        this.replaceLoader({ loader: false });
                    });
            },
            click() {
                this.searchdevice();
                this.getLifecycles();
            },
            init: function () {
                if (!this.getProfile) return;
            }
        },
        watch: {
            getProfile: function () {
                this.init();
            }
        },
        mounted() {
            this.init();

        }
    }
</script>
<style scoped>
</style>