<template>
    <div>
        <PopupWindow @closePopup="closePopup" :coverWindow="true">
            <center>

                <h3>Edit</h3>
                <div>
                    <table>
                        <tr>
                            <td>Device:</td>
                            <td><b>{{this.data.devicesDeviceUid}}</b></td>
                        </tr>
                        <tr>
                            <td>Asset:</td>
                            <td><b>{{this.data.assetName}}</b></td>
                        </tr>
                    </table>
                </div>

                <div>

                    <div>
                        <div><label>Building</label></div>
                        <select>
                            <option v-for="(building) in planningBuildingList" :key="building.id" :value=building>{{building.name}}</option>
                        </select>
                    </div>
                    <div>
                        <div><label>Floor</label></div>
                        <select v-model="mappingselctedfloor">
                            <option v-for="(building) in planningFloorList" :key="building.id" :value=building>{{building.name}}</option>
                        </select>
                    </div>
                    <div>
                        <h4>Asset Search</h4>
                        <div>
                            <input v-model=searchstring /> <button @click="search" class="box">search</button>
                        </div>
                    </div>

                    <br />
                    <div>
                        <div><label>Asset</label></div>
                        <select v-model="selectedasset">
                            <option v-for="(asset,k) in assetlist" :key="k" :value=asset>{{asset.name}}</option>
                        </select>
                    </div>
                    <div>
                        <br />
                        <button  @click="remap(data,selectedasset)" class="box">remap</button>
                        <br />
                    </div>
                    <div v-if="this.installationresult.length>0">
                        <br />
                        <label>Result</label>
                        <div v-for="(result,k) in installationresult" :key="k">
                            <label>{{result}}</label>
                        </div>
                    </div>
                </div>
            </center>
        </PopupWindow>
    </div>
</template>

<script>
    import PopupWindow from "@/components/default/PopupWindow.vue"
    import { mapActions, mapGetters } from "vuex"
    import axios from "axios"
    export default {
        name: "DeviceAssetMappingEdit",
        props: ["mappingData"],
        components: {
            PopupWindow,
        },
        data() {
            return {
                data: this.mappingData,
                mappingselctedfloor: null,
                searchstring: null,
                selectedasset: null,
                assetlist: [],
                installationresult: []
            }
        },
        computed: {
            ...mapGetters("keycloak", {
                hasClientRoles: "hasClientRoles"
            }),
            ...mapGetters("lang", {
                getText: "getText"
            }),
            planningBuildingList: {
                get() {
                    return this.$store.state.dit.planningBuildingList
                },
                set(value) {
                    this.$store.commit('dit/setPlanningBuildingList', value)
                }
            },
            planningFloorList: {
                get() {
                    return this.$store.state.dit.planningFloorList
                },
                set(value) {
                    this.$store.commit('dit/setPlanningFloorList', value)
                }
            },

        },
        methods: {
            ...mapActions("loader", {
                replaceLoader: "replaceLoader"
            }),
            closePopup: function () {
                this.$emit("closePopup");
            },
            search() {
                this.assetlist = null;
                this.replaceLoader({ loader: true });
                return axios.get("/api/dit/FindSpaceForPlanning",
                    {
                        params: { SearchString: this.searchstring },
                        headers: {
                            'ConnectionName': this.$store.state.connection.connectionstring,
                            'FloorID': this.mappingselctedfloor.id
                        }
                    }).then(res => {
                        if (!res.data.status) {
                            throw res.data.message[0];
                        }
                        this.assetlist = res.data.data;
                    }).catch(e => {
                        alert(e.message);
                    }).finally(() => {
                        this.replaceLoader({ loader: false });
                    });
            },
            remap(datavar, selectedassetvar) {
                if (this.selectedasset !== null && this.datavar !== null && this.selectedassetvar !== null) {
                    this.replaceLoader({ loader: true });
                    return axios.post("/api/dit/RemapDeviceAsset", "",
                        {
                            headers: {
                                'ConnectionName': this.$store.state.connection.connectionstring,
                                'DeviceID': datavar.devicesId,
                                'OldAssetID': datavar.assetId,
                                'NewAssetID': selectedassetvar.id,
                            }
                        }).then(res => {
                            if (!res.data.status) {
                                throw res.data.message[0];
                            }
                            switch (res.data.data) {
                                case "-1":
                                    this.installationresult.push(`${this.data.devicesDeviceUid} remapping to ${this.selectedasset.name} failed`);
                                    break;
                                case "1":
                                    this.installationresult.push(`${this.data.devicesDeviceUid} mapping to ${this.selectedasset.name} was sucessful`);
                                    break;
                                case "2":
                                    this.installationresult.push(`${this.data.devicesDeviceUid} mapping to ${this.selectedasset.name} already existed`);
                                    break;
                                default:
                                    break;
                            }
                        }).catch(e => {
                            alert(e.message);
                        }).finally(() => {
                            this.selectedDevice = null;
                            this.searchstring = null;
                            this.replaceLoader({ loader: false });

                        });
                }
            },
        },
        mounted() {
        }
    }
</script>

<style scoped>
    .column-wrapper {
        margin-bottom: 0;
        margin-top: var(--margin-05);
        margin-left: calc(-1 * var(--margin-075));
        width: calc(100% + 2 * var(--margin-075));
        display: flex;
        flex-wrap: wrap;
    }

        .column-wrapper + div {
            margin-top: var(--margin-075);
        }

        .column-wrapper > div {
            padding: 0 var(--margin-075);
            padding-top: var(--margin-05);
        }

        .column-wrapper > .grow {
            flex-grow: 1;
        }

    .canvas-wrapper {
        max-width: 100%;
    }

    .ts-small,
    .ts {
        font-size: var(--smaller);
    }

        .ts > * {
            vertical-align: middle;
        }

        .ts > button {
            margin-left: var(--margin-025);
        }

            .ts > button > span {
                margin-left: var(--margin-0125) !important;
            }

    .disabled-obj {
        margin-left: var(--margin-025);
    }

    .tririga-id input {
        font-size: var(--smaller);
    }

    .tririga-id input {
        width: calc(22 * var(--smaller));
        max-width: 80%;
        display: inline-block;
    }

        select + div,
        .tririga-id input + button {
            display: inline-block;
            margin-left: var(--margin-025);
        }

    .more-options {
        font-size: var(--smaller);
    }

        .more-options:not(:last-child) {
            margin-bottom: var(--margin-075);
        }

        .more-options > [class*="dexcon-arrow-"] {
            margin-left: var(--margin-0125) !important;
        }

    table {
        overflow: hidden;
    }

    .temperature-line {
        margin-top: var(--margin-05);
    }
</style>