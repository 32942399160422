export default ({
    namespaced: true,
    state: {
        selectedInstallableCity: null,
        installableCityList: {},
        selectedInstallableBuilding:null,
        installebleBuildingList: {},
        selectedInstallableFloor: null,
        installebleFloorList: {},
        selectedDeviceForInstallation: null,
        selectedDeviceTypeForPlanning: null,
        plannedInstallationData: null,
        deviceTypeList: null,
        selectedDeviceType: null,
        selectedPlanningCity: null,
        planningCityList: {},
        selectedPlanningBuilding: null,
        planningBuildingList: {},
        selectedPlanningFloor: null,
        planningFloorList: {},
    },

    mutations: {
        setSelectedInstallableCity: function (state, payload) {
            if (payload == undefined) return;
            state.selectedInstallableCity = payload;
        },
        setInstallableCityList: function (state, payload) {
            if (payload == undefined) return;
            state.installableCityList = payload;
        },
        setSelectedInstallableBuilding: function (state, payload) {
            if (payload == undefined) return;
            state.selectedInstallableBuilding = payload;
        },
        setInstallebleBuildingList: function (state, payload) {
            if (payload == undefined) return;
            state.installebleBuildingList = payload;
        },
        setSelectedInstallableFloor: function (state, payload) {
            if (payload == undefined) return;
            state.selectedInstallableFloor = payload;
        },
        setInstallebleFloorList: function (state, payload) {
            if (payload == undefined) return;
            state.installebleFloorList = payload;
        },
        setSelectedDeviceForInstallation: function (state, payload) {
            if (payload == undefined) return;
            state.selectedDeviceForInstallation = payload;
        },
        setSelectedDeviceTypeForPlanning: function (state, payload) {
            if (payload == undefined) return;
            state.selectedDeviceTypeForPlanning = payload;
        },
        setPlannedInstallationData: function (state, payload) {
            if (payload == undefined) return;
            state.plannedInstallationData = payload;
        },
        setDeviceTypeList: function (state, payload) {
            if (payload == undefined) return;
            state.deviceTypeList = payload;
        },
        setSelectedDeviceType: function (state, payload) {
            if (payload == undefined) return;
            state.selectedDeviceType = payload;
        },
        setSelectedPlanningCity: function (state, payload) {
            if (payload == undefined) return;
            state.selectedPlanningCity = payload;
        },
        setPlanningCityList: function (state, payload) {
            if (payload == undefined) return;
            state.planningCityList = payload;
        },
        setSelectedPlanningBuilding: function (state, payload) {
            if (payload == undefined) return;
            state.selectedPlanningBuilding = payload;
        },
        setPlanningBuildingList: function (state, payload) {
            if (payload == undefined) return;
            state.planningBuildingList = payload;
        },
        setSelectedPlanningFloor: function (state, payload) {
            if (payload == undefined) return;
            state.selectedPlanningFloor = payload;
        },
        setPlanningFloorList: function (state, payload) {
            if (payload == undefined) return;
            state.planningFloorList = payload;
        }

        
    }
})