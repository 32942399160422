<template>
    <div class="pad-child" align="center">
        <div v-if="hasClientRoles(['ditdev'])">
            <h2>Overview</h2>
            <PlanningSelector :level="2" ref="childRef"/>
            <br />
            <div style="height: 75vh; width: 50vw;">
                <l-map :zoom="zoom" :center="buildingcoords">
                    <l-tile-layer :options="{ maxNativeZoom: 18, maxZoom: 100 }"
                                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" max>
                    </l-tile-layer>
                    <l-control>
                        <button>
                            I am a useless button!
                        </button>
                    </l-control>
                    <l-marker :lat-lng="markercoords" :draggable="draggable"></l-marker>
                </l-map>
            </div>
        </div>
        <div v-else>
            <h2>401 Unauthorized</h2>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from "vuex"
    //import axios from "axios"
    import PlanningSelector from '../components/dit/PlanningSelector.vue'
    import { LMap, LTileLayer, LMarker, LControl } from 'vue2-leaflet'
    import "leaflet/dist/leaflet.css";
    export default {
        name: "DITMap",
        data() {
            return {
                zoom: 20,
                iconWidth: 25,
                iconHeight: 40,
                draggable: false,
                buildingcoords: [51.27409782857829, 6.810519814163331],
                markercoords: [51.27409782857829, 6.810519814163331],
                bounds: [[51.27409782857829, 6.810519814163331], [51.3, 6.9]],
                url: 'http://leafletjs.com/examples/crs-simple/uqm_map_full.png',
            }
        },
        components: {
            PlanningSelector,
            LMap,
            LTileLayer,
            LMarker,
            LControl,
        },
        computed: {
            ...mapGetters("lang", {
                getText: "getText"
            }),
            ...mapGetters("keycloak", {
                getProfile: "getProfile",
                hasClientRoles: "hasClientRoles"
            }),
        },
        methods: {
            ...mapActions("loader", {
                replaceLoader: "replaceLoader"
            }),
            init: function () {
                if (!this.getProfile) return;
            }
        },
        watch: {
            getProfile: function () {
                this.init();
            }
        },
        mounted() {
            this.init();
        }
    }
</script>

<style scoped>
</style>