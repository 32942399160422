import { render, staticRenderFns } from "./DITAssetMapping.vue?vue&type=template&id=262e1232&scoped=true"
import script from "./DITAssetMapping.vue?vue&type=script&lang=js"
export * from "./DITAssetMapping.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "262e1232",
  null
  
)

export default component.exports