import { render, staticRenderFns } from "./DITPlanning.vue?vue&type=template&id=824e9a06&scoped=true"
import script from "./DITPlanning.vue?vue&type=script&lang=js"
export * from "./DITPlanning.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "824e9a06",
  null
  
)

export default component.exports