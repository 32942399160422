import { render, staticRenderFns } from "./DexHeaderDropdownGroup.vue?vue&type=template&id=289f3296&scoped=true"
import script from "./DexHeaderDropdownGroup.vue?vue&type=script&lang=js"
export * from "./DexHeaderDropdownGroup.vue?vue&type=script&lang=js"
import style0 from "./DexHeaderDropdownGroup.vue?vue&type=style&index=0&id=289f3296&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "289f3296",
  null
  
)

export default component.exports