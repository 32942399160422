export default ({
    namespaced: true,
    state: {
        connectionstring: ''
    },
    mutations: {
        setConnection: function (state, payload) {
            //console.log("me",state,payload)
            if (payload == undefined) return;
            state.connectionstring = payload;
        }
    }
})