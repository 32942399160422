<template>
  <div class="pad-child">
    <div>
      <h2>{{ getText("imprint-header") }}</h2>
      <h3>Anschrift</h3>
      <p>
        ISS Facility Services Holding GmbH<br />
        Theodorstrasße 178<br />
        D-40472 Düsseldorf, Deutschland<br />
        Tel.: +49 211 / 30 278-0<br />
        Mail: <a href="mailto:info@de.issworld.com">info@de.issworld.com</a
        ><br />
      </p>
      <h3>Geschäftsführung</h3>
      <p>
          Gudrun Degenhart <br />
          Florian Eisenmagen <br />
          Anna Hellweg <br />
          <br />
          Amtsgericht Düsseldorf HRB 54127<br />
          UStIDNr. 209658047<br />
      </p>
      <h3>Hinweis</h3>
      <p>
        Mit Benutzung dieser Seite akzeptieren Sie die Geschäftsbedingungen,
        welche in der Copyright-Mitteilung sowie der Ausschlußklausel erläutert
        werden. ISS A/S ist dazu berechtigt, von Zeit zu Zeit Änderungen der
        Copyright-Mitteilungen und Ausschlußklauseln, welche nachstehend
        dargelegt werden, vorzunehmen.
      </p>
      <h3>Ausschlussklausel</h3>
      <p>
        Wir erklären ausdrücklich, dass wir keinerlei Einfluss auf die aktuelle
        und zukünftige Gestaltung, die Inhalte oder die Urheberschaft der
        gelinkten/verknüpften Seiten haben. Wir distanzieren uns ausdrücklich
        von allen Inhalten aller gelinkten/verknüpften Seiten auf dieser
        Internetpräsenz und machen uns deren Inhalte nicht zu Eigen. Diese
        Feststellung gilt für alle innerhalb des eigenen Internetangebots
        gesetzten Links und Verweise sowie für Fremdeinträge in Gästebüchern,
        Diskussionsforen und Mailinglisten. Für illegale, fehlerhafte oder
        unvollständige Inhalte und insbesondere für Schäden, die aus der Nutzung
        oder Nicht-Nutzung solcher Art dargebotener Informationen entstehen,
        haftet allein der Anbieter der Seite, auf welche verwiesen wurde, nicht
        derjenige, der über Links auf die jeweilige Veröffentlichung lediglich
        verweist.<br />
        <br />
        ISS ist bemüht, seinen Kunden einen aktuellen Seiteninhalt zu bieten.
        Jedoch wird auf Zusicherungen hinsichtlich der inhaltlichen Angaben über
        Genauigkeit, Sequenz, Datenaktualität oder Vollständigkeit auf dieser
        Website verzichtet.<br />
        <br />
        ISS A/S ist jeder Zeit dazu bevollmächtigt, das Format sowie den Inhalt
        dieser Website zu verändern.<br />
        <br />
        ISS A/S kann den Betriebsvorgang dieser Website aus irgendeinem Grund
        einstellen und sich das Recht vorbehalten zu jeder Zeit fristlos den
        Zugriff auf diese Website zu beenden.<br />
        <br />
        Die ISS A/S kann nicht für unmittelbare, indirekte oder zufällige
        Folgeschäden, die aus dem Zugriff, der Nutzung und dem Vertrauen
        hinsichtlich der Website resultieren, haftbar gemacht werden.<br />
        <br />
        Außerdem ist die ISS A/S nicht verpflichtet, z.B. entstandenen Schaden,
        Verlust oder Entschädigung, resultierend aus den zuvor genannten Folgen,
        zu zahlen.<br />
        <br />
        Diese Copyright-Mitteilung, die Ausschlussklausel sowie der Inhalt
        dieser Website werden von den Danish Courts in Kopenhagen, Dänemark,
        verwaltet.
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("lang", {
      getText: "getText"
    })
  }
}
</script>